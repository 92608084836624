var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mx-4 my-4 pa-4 elevation-10 rounded-lg"},[_c('v-flex',{staticClass:"d-flex"},[_c('v-autocomplete',{attrs:{"items":_vm.itemsFdivision,"item-value":"kode1","item-text":"description","outlined":"","dense":"","small-chips":"","label":"Cabang/Division"},model:{value:(_vm.searchFdivision),callback:function ($$v) {_vm.searchFdivision=$$v},expression:"searchFdivision"}}),_c('v-text-field',{staticClass:"mx-1",attrs:{"append-icon":"mdi-magnify","label":"Filter dengan kode produk","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticClass:"mx-4 my-4 pa-2 elevation-10 rounded-lg"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headersStockCoverDay,"items":_vm.computedItemsWarningStockWithStockCoverDay,"search":_vm.search,"page":_vm.currentPage,"items-per-page":_vm.pageSize,"dense":""},on:{"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((index + ((_vm.currentPage-1)*_vm.pageSize) +1 ))+" ")]}},{key:"item.fmaterialPcode",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.fmaterialPcode))]),_c('div',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.lookupFMaterial(item.fmaterialBean).pname)+" ")])]}},{key:"item.saldoStockInPcs",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.saldoStockInPcs))]),_c('div',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.lookupUom1234StringUom(_vm.lookupFMaterial(item.fmaterialBean), Math.abs(item.saldoStockInPcs)))+" ")])]}},{key:"item.salesAvgQtyInPcs",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(Math.round(item.salesAvgQtyInPcs)))]),_c('div',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.lookupUom1234StringUom(_vm.lookupFMaterial(item.fmaterialBean), Math.abs(item.salesAvgQtyInPcs)))+" ")])]}},{key:"item.stockMinimumInPcs",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(Math.round(item.stockMinimumInPcs)))]),_c('div',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.lookupUom1234StringUom(_vm.lookupFMaterial(item.fmaterialBean), Math.abs(item.stockMinimumInPcs)))+" ")])]}},{key:"item.warningStockInPcs",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.warningStockInPcs))]),_c('div',{staticClass:"caption grey--text red--text"},[_vm._v(" "+_vm._s(_vm.lookupUom1234StringUom(_vm.lookupFMaterial(item.fmaterialBean), Math.abs(item.warningStockInPcs)))+" ")])]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"caption",attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-bold ml-2 green--text"},[_vm._v(" Metode Minimum Stock with StockCover Days: ")]),_c('div',[_c('span',{staticClass:"ma-2"},[_vm._v("Lead Time (waktu pengirman maksimum dari Pabrik ke Gudang) = ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("21 Hari")])]),_c('div',[_c('span',{staticClass:"ma-2"},[_vm._v("Rata-rata Penjualan = ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("8 Minggu (8 x 7 hari = 56 hari)")])]),_c('div',[_c('span',{staticClass:"ma-2 blue--text"},[_vm._v("Rumus Stock Minimum = ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("rata-rata penjualan x Lead Time")])])])],1)],1),_c('div',{staticClass:"mx-4 my-4 pa-2 elevation-10 rounded-lg"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headersSafetyStock,"items":_vm.computedItemsWarningStockWithSafetyStock,"search":_vm.search,"page":_vm.currentPage,"items-per-page":_vm.pageSize,"dense":""},on:{"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((index + ((_vm.currentPage-1)*_vm.pageSize) +1 ))+" ")]}},{key:"item.fmaterialPcode",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.fmaterialPcode))]),_c('div',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.lookupFMaterial(item.fmaterialBean).pname)+" ")])]}},{key:"item.saldoStockInPcs",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.saldoStockInPcs))]),_c('div',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.lookupUom1234StringUom(_vm.lookupFMaterial(item.fmaterialBean), Math.abs(item.saldoStockInPcs)))+" ")])]}},{key:"item.salesAvgQtyInPcs",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_vm._v(" "+_vm._s(Math.round(item.salesAvgQtyInPcs))+" ")]),_c('span',{staticClass:"grey--text caption font-weight-light"},[_vm._v(" x28 ")]),_c('span',{staticClass:"grey--text caption font-weight-light"},[_vm._v(" x"+_vm._s(_vm.koefisienLeadTime)+" ")])]),_c('div',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.lookupUom1234StringUom(_vm.lookupFMaterial(item.fmaterialBean), Math.abs(item.salesAvgQtyInPcs)))+" ")])]}},{key:"item.bufferStockInPcs",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(Math.round(item.bufferStockInPcs)))]),_c('div',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.lookupUom1234StringUom(_vm.lookupFMaterial(item.fmaterialBean), Math.abs(item.bufferStockInPcs)))+" ")])]}},{key:"item.warningStockInPcs",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.warningStockInPcs))]),_c('div',{staticClass:"caption grey--text red--text"},[_vm._v(" "+_vm._s(_vm.lookupUom1234StringUom(_vm.lookupFMaterial(item.fmaterialBean), Math.abs(item.warningStockInPcs)))+" ")])]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"caption",attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-bold ml-2 green--text"},[_vm._v(" Metode Safety Stock with Kooefisien: ")]),_c('div',[_c('span',{staticClass:"ma-2"},[_vm._v("Lead Time (waktu pengirman maksimum dari Pabrik ke Gudang) = ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("21 Hari")])]),_c('div',[_c('span',{staticClass:"ma-2"},[_vm._v("Rata-rata Penjualan = ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("1 Bulan (30 hari)")])]),_c('div',[_c('span',{staticClass:"ma-2"},[_vm._v("Kooefisien = ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.koefisienLeadTime))])]),_c('div',[_c('span',{staticClass:"ma-2 blue--text"},[_vm._v("Rumus Buffer Stock = ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.koefisienLeadTime)+" × rata-rata penjualan 1 bulan ")])]),_c('div',[_c('span',{staticClass:"ma-2 "},[_vm._v("Rata-rata penjualan 1 bulan = ")]),_c('span',{},[_vm._v("penjualan per hari * 28 ")])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }