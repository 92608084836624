<template>
  <div>

    <div class="mx-4 my-4 pa-4 elevation-10 rounded-lg">
      <v-flex class="d-flex">
<!--        <v-text-field-->
<!--            v-model="searchFdivision"-->
<!--            label="Filter dengan kode Cabang"-->
<!--            hide-details-->
<!--            class="mx-1"-->
<!--        ></v-text-field>-->

        <v-autocomplete
            v-model="searchFdivision"
            :items="itemsFdivision"
            item-value="kode1"
            item-text="description"
            outlined
            dense
            small-chips
            label="Cabang/Division"
        ></v-autocomplete>

        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Filter dengan kode produk"
            hide-details
            class="mx-1"
            dense
            outlined
        ></v-text-field>

      </v-flex>

    </div>

    <div class="mx-4 my-4 pa-2 elevation-10 rounded-lg">
      <v-row>
        <v-col cols="12">
          <v-data-table
              :headers="headersStockCoverDay"
              :items="computedItemsWarningStockWithStockCoverDay"
              :search="search"
              :page.sync="currentPage"
              :items-per-page = "pageSize"
              dense
          >
            <template v-slot:[`item.number`]="{ index }">
              {{ (index + ((currentPage-1)*pageSize) +1 )}}
            </template>
            <template v-slot:[`item.fmaterialPcode`]="{ item }">
              <div>{{ item.fmaterialPcode }}</div>
              <div class="caption grey--text">
                {{ lookupFMaterial(item.fmaterialBean).pname }}
              </div>
            </template>

            <template v-slot:[`item.saldoStockInPcs`]="{ item }">
              <div>{{ item.saldoStockInPcs }}</div>
              <div class="caption grey--text">
                {{ lookupUom1234StringUom(lookupFMaterial(item.fmaterialBean), Math.abs(item.saldoStockInPcs)) }}
              </div>
            </template>
            <template v-slot:[`item.salesAvgQtyInPcs`]="{ item }">
              <div>{{ Math.round(item.salesAvgQtyInPcs) }}</div>
              <div class="caption grey--text">
                {{ lookupUom1234StringUom(lookupFMaterial(item.fmaterialBean), Math.abs(item.salesAvgQtyInPcs)) }}
              </div>
            </template>
            <template v-slot:[`item.stockMinimumInPcs`]="{ item }">
              <div>{{ Math.round(item.stockMinimumInPcs) }}</div>
              <div class="caption grey--text">
                {{ lookupUom1234StringUom(lookupFMaterial(item.fmaterialBean), Math.abs(item.stockMinimumInPcs)) }}
              </div>
            </template>

            <template v-slot:[`item.warningStockInPcs`]="{ item }">
              <div >{{ item.warningStockInPcs }}</div>
              <div class="caption grey--text red--text">
                {{ lookupUom1234StringUom(lookupFMaterial(item.fmaterialBean), Math.abs(item.warningStockInPcs)) }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" class="caption">
          <div class="font-weight-bold ml-2 green--text">
            Metode Minimum Stock with StockCover Days:
          </div>
          <div>
            <span class="ma-2">Lead Time (waktu pengirman maksimum dari Pabrik ke Gudang) = </span>
            <span class="font-weight-bold">21 Hari</span>
          </div>
          <div>
            <span class="ma-2">Rata-rata Penjualan = </span>
            <span class="font-weight-bold">8 Minggu (8 x 7 hari = 56 hari)</span>
          </div>
          <div>
            <span class="ma-2 blue--text">Rumus Stock Minimum = </span>
            <span class="font-weight-bold">rata-rata penjualan x Lead Time</span>
          </div>

        </v-col>
      </v-row>
    </div>


    <div class="mx-4 my-4 pa-2 elevation-10 rounded-lg">
      <v-row>
        <v-col cols="12">
          <v-data-table
              :headers="headersSafetyStock"
              :items="computedItemsWarningStockWithSafetyStock"
              :search="search"
              :page.sync="currentPage"
              :items-per-page = "pageSize"
              dense
          >
            <template v-slot:[`item.number`]="{ index }">
              {{ (index + ((currentPage-1)*pageSize) +1 )}}
            </template>

            <template v-slot:[`item.fmaterialPcode`]="{ item }">
              <div>{{ item.fmaterialPcode }}</div>
              <div class="caption grey--text">
                {{ lookupFMaterial(item.fmaterialBean).pname }}
              </div>
            </template>

            <template v-slot:[`item.saldoStockInPcs`]="{ item }">
              <div>{{ item.saldoStockInPcs }}</div>
              <div class="caption grey--text">
                {{ lookupUom1234StringUom(lookupFMaterial(item.fmaterialBean), Math.abs(item.saldoStockInPcs)) }}
              </div>
            </template>
            <template v-slot:[`item.salesAvgQtyInPcs`]="{ item }">
              <div>
                <span>
                  {{ Math.round(item.salesAvgQtyInPcs) }}
                </span>
                <span class="grey--text caption font-weight-light">
                    x28
                </span>
                <span class="grey--text caption font-weight-light">
                    x{{koefisienLeadTime}}
                </span>
              </div>
              <div class="caption grey--text">
<!--                {{ item.salesAvgQtyInPcs *28 *1.75 }}-->
                {{ lookupUom1234StringUom(lookupFMaterial(item.fmaterialBean), Math.abs(item.salesAvgQtyInPcs)) }}
              </div>
            </template>
            <template v-slot:[`item.bufferStockInPcs`]="{ item }">
              <div>{{ Math.round(item.bufferStockInPcs) }}</div>
              <div class="caption grey--text">
                {{ lookupUom1234StringUom(lookupFMaterial(item.fmaterialBean), Math.abs(item.bufferStockInPcs)) }}
              </div>
            </template>

            <template v-slot:[`item.warningStockInPcs`]="{ item }">
              <div >{{ item.warningStockInPcs }}</div>
              <div class="caption grey--text red--text">
                {{ lookupUom1234StringUom(lookupFMaterial(item.fmaterialBean), Math.abs(item.warningStockInPcs)) }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" class="caption">
          <div class="font-weight-bold ml-2 green--text">
            Metode Safety Stock with Kooefisien:
          </div>
          <div>
            <span class="ma-2">Lead Time (waktu pengirman maksimum dari Pabrik ke Gudang) = </span>
            <span class="font-weight-bold">21 Hari</span>
          </div>
          <div>
            <span class="ma-2">Rata-rata Penjualan = </span>
            <span class="font-weight-bold">1 Bulan (30 hari)</span>
          </div>
          <div>
            <span class="ma-2">Kooefisien = </span>
            <span class="font-weight-bold">{{koefisienLeadTime}}</span>
          </div>
          <div>
            <span class="ma-2 blue--text" >Rumus Buffer Stock = </span>
<!--            <span class="font-weight-bold">{{koefisienLeadTime}} × (rata-rata penjulan 30 hari : 25<span class="font-weight-light">(hari kerja efektif)</span> ) x 15 = 787 lembar</span>-->
              <span class="font-weight-bold">{{koefisienLeadTime}} × rata-rata penjualan 1 bulan </span>
          </div>
          <div>
            <span class="ma-2 " >Rata-rata penjualan 1 bulan = </span>
            <span class="">penjualan per hari * 28 </span>
          </div>

        </v-col>
      </v-row>
    </div>

  </div>

</template>

<script>
import FtSaleshFilter from "@/models/payload/ft-salesh-filter";
import FStockService from "@/services/apiservices/f-stock-service";
import FMaterialService from "@/services/apiservices/f-material-service";
import FDivisionService from "@/services/apiservices/f-division-service";
import FtSalesExtService from "@/services/apiservices/ft-sales-ext-service";
import KonversiProductHelper from "@/services/konversi-product-helper";

export default {
  name: "WarningStockTable",
  components:{

  },
  data(){
    return{
      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search : '',
      searchFdivision: '',

      headersStockCoverDay: [
        {
          text: 'No',
          value: 'number',
          width: '5%',
          sortable: false
        },
        { text: 'Division', value: 'fdivisionKode1', width: '8%' },
        { text: 'Produk', value: 'fmaterialPcode', width: '15%' },
        { text: 'Stok In Pcs', value: 'saldoStockInPcs', width: '10%'  },
        { text: 'Sales Avg (56days)', value: 'salesAvgQtyInPcs', width: '10%'  },
        { text: 'Stock Minimum (Lead:21days)', value: 'stockMinimumInPcs', width: '10%'  },
        { text: 'Warning Stock', value: 'warningStockInPcs', width: '10%'  }
      ],
      headersSafetyStock: [
        {
          text: 'No',
          value: 'number',
          width: '5%',
          sortable: false
        },
        { text: 'Division', value: 'fdivisionKode1', width: '8%' },
        { text: 'Produk', value: 'fmaterialPcode', width: '15%' },
        { text: 'Stok In Pcs', value: 'saldoStockInPcs', width: '10%'  },
        { text: 'Sales Avg (1month)', value: 'salesAvgQtyInPcs', width: '10%'  },
        { text: 'BufferStock', value: 'bufferStockInPcs', width: '10%'  },
        { text: 'Warning Stock', value: 'warningStockInPcs', width: '10%'  }
      ],

      paramSalesHistoryDays: 56, //8x7hari
      paramLeadTime: 21, //21 hari

      koefisienLeadTime: 1.75,

      itemsFmaterial: [],
      itemsFdivision: [],
      itemsSaldoStockPerDiv: [],
      itemsSalesAvg: [],
      itemsSalesWithDaysAvg: [],

    }
  },
  computed:{
    computedItemsWarningStockWithStockCoverDay(){
      let newItems = []
      let index = 0
      this.itemsFdivision.filter(x =>x.kode1 != '00').forEach(itemFdivision => {
        this.itemsFmaterial.forEach(itemFmaterial => {

          let stocks =  this.itemsSaldoStockPerDiv.filter(x => x.fmaterialBean == itemFmaterial.id && x.fdivisionBean == itemFdivision.id)

          let saldoStockInPcsQty = 0
          if (stocks.length>0){
            saldoStockInPcsQty = stocks[0].sumResult
            // console.log(`${itemFmaterial.pcode} >> ${saldoStockInPcsQty}`)
          }
          let salesAvgQtyInPcss =  this.itemsSalesAvg.filter(x => x.fmaterialBean == itemFmaterial.id && x.fdivisionBean == itemFdivision.id)
          let salesAvgQtyInPcsQty = 0
          if (salesAvgQtyInPcss.length>0){
            salesAvgQtyInPcsQty = salesAvgQtyInPcss[0].sumResult
            // console.log(`${itemFmaterial.pcode} >> ${salesAvgQtyInPcsQty}`)
          }

          /**
           * 21 Hari
           * @type {number}
           */
          let stockMinimumInPcsQty = salesAvgQtyInPcsQty * this.paramLeadTime
          let warningStockInPcsQty = saldoStockInPcsQty - stockMinimumInPcsQty

          let newItem = {id: index++, fdivisionBean: itemFdivision.id, fdivisionKode1: itemFdivision.kode1,
              fmaterialBean: itemFmaterial.id, fmaterialPcode: itemFmaterial.pcode, saldoStockInPcs: saldoStockInPcsQty, salesAvgQtyInPcs: salesAvgQtyInPcsQty.toFixed(1),
              stockMinimumInPcs: stockMinimumInPcsQty.toFixed(1), warningStockInPcs: Math.round(warningStockInPcsQty) }

          // if (salesAvgQtyInPcsQty>0){
          // if (saldoStockInPcsQty>0){
          if (warningStockInPcsQty<0 && saldoStockInPcsQty>0){
            newItems.push(newItem)
          }

        })
      })
      
      return newItems.filter(x => x.fdivisionKode1.includes(this.searchFdivision)).sort((a, b) => (a.warningStockInPcs > b.warningStockInPcs)? 1: -1)
    },

    computedItemsWarningStockWithSafetyStock(){
      let newItems = []
      let index = 0
      this.itemsFdivision.filter(x =>x.kode1 != '00').forEach(itemFdivision => {
        this.itemsFmaterial.forEach(itemFmaterial => {

          let stocks =  this.itemsSaldoStockPerDiv.filter(x => x.fmaterialBean == itemFmaterial.id && x.fdivisionBean == itemFdivision.id)

          let saldoStockInPcsQty = 0
          if (stocks.length>0){
            saldoStockInPcsQty = stocks[0].sumResult
            // console.log(`${itemFmaterial.pcode} >> ${saldoStockInPcsQty}`)
          }
          let salesAvgQtyInPcss =  this.itemsSalesWithDaysAvg.filter(x => x.fmaterialBean == itemFmaterial.id && x.fdivisionBean == itemFdivision.id)
          let salesAvgQtyInPcsQty = 0
          if (salesAvgQtyInPcss.length>0){
            salesAvgQtyInPcsQty = salesAvgQtyInPcss[0].sumResult
            // console.log(`${itemFmaterial.pcode} >> ${salesAvgQtyInPcsQty}`)
          }

          /**
           * 21 Hari
           * @type {number}
           */
          let bufferStockInPcsQty = this.koefisienLeadTime * salesAvgQtyInPcsQty *28
          let warningStockInPcsQty = saldoStockInPcsQty - bufferStockInPcsQty

          let newItem = {id: index++, fdivisionBean: itemFdivision.id, fdivisionKode1: itemFdivision.kode1,
            fmaterialBean: itemFmaterial.id, fmaterialPcode: itemFmaterial.pcode, saldoStockInPcs: saldoStockInPcsQty, salesAvgQtyInPcs: salesAvgQtyInPcsQty.toFixed(1),
            bufferStockInPcs: bufferStockInPcsQty.toFixed(1), warningStockInPcs: Math.round(warningStockInPcsQty) }

          // if (salesAvgQtyInPcsQty>0){
          // if (saldoStockInPcsQty>0){
          if (warningStockInPcsQty<0 && saldoStockInPcsQty>0){
            newItems.push(newItem)
          }

        })
      })

      return newItems.filter(x => x.fdivisionKode1.includes(this.searchFdivision)).sort((a, b) => (a.warningStockInPcs > b.warningStockInPcs)? 1: -1)
    }

  },
  methods:{


    lookupUom1234StringUom(fmaterial, pcsOrUom4){
      return KonversiProductHelper.getUom1234StringUom(fmaterial, pcsOrUom4)
    },

    runExtendedFilter(){

      const ftSaleshFilter = new FtSaleshFilter()
      const date = new Date()
      const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())

      ftSaleshFilter.invoiceDateFrom =  new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()- this.paramSalesHistoryDays)
      ftSaleshFilter.invoiceDateTo =  endDate

      FtSalesExtService.getSumQtyFmaterialSalesdItemsAverageByFdivisionBean(ftSaleshFilter).then(
          response =>{
            this.itemsSalesAvg = response.data.map( item =>{
              const newItem = item
              newItem.fmaterialBean = this.lookupFMaterial(item.id).id

              newItem.fdivisionBean = this.lookupFdivision(item.countResult).id

              newItem.id = i++
              return newItem
            })
          },
          error =>{
            console.log(JSON.stringify(error))
          }
      )


      const ftSaleshFilterPerBulan = new FtSaleshFilter()
      ftSaleshFilterPerBulan.invoiceDateFrom =  new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()-30)
      ftSaleshFilterPerBulan.invoiceDateTo =  endDate

      ftSaleshFilterPerBulan.pageNo = 28 //(4 minggu)

      FtSalesExtService.getSumQtyFmaterialSalesdItemsAverageByFdivisionBeanAndDays(ftSaleshFilterPerBulan).then(
          response =>{
            this.itemsSalesWithDaysAvg = response.data.map( item =>{
              const newItem = item
              newItem.fmaterialBean = this.lookupFMaterial(item.id).id

              newItem.fdivisionBean = this.lookupFdivision(item.countResult).id

              newItem.id = i++
              return newItem
            })
          },
          error =>{
            console.log(JSON.stringify(error))
          }
      )


      let i = 0
      FStockService.getAllByFmaterialBeanInAndFwarehouseBeanInAndStockDateGoodStockOnly(ftSaleshFilter).then(
          response =>{
              this.itemsSaldoStockPerDiv = response.data.map( item =>{
                const newItem = item
                newItem.fmaterialBean = this.lookupFMaterial(item.id).id
                // newItem.fmaterialPcode = this.lookupFMaterial(item.id).pcode

                newItem.fdivisionBean = this.lookupFdivision(item.countResult).id
                // newItem.fdivisionKode1 = this.lookupFdivision(item.countResult).kode1

                newItem.id = i++
                return newItem
              })

            // console.log(JSON.stringify(response.data))

          },
          error =>{
            console.log(error)
          }
      )
    },

    fetchParent(){
      FDivisionService.getAllFDivision().then(
          response => {
            this.itemsFdivision = response.data
          }
      )
      FMaterialService.getAllFMaterial().then(
          response => {
            this.itemsFmaterial = response.data
            /**
             * Karena akan Telat karena Fmaterial yang terbesar
             */
            this.runExtendedFilter()
          }
      )
    },
    lookupFdivision(fdivisionBean){
      let item = this.itemsFdivision.filter(x => x.id==fdivisionBean)
      if (item.length>0){
        return item[0]
      }else {
        return '-'
      }
    },
    lookupFMaterial(fmaterialBean){
      let item = this.itemsFmaterial.filter(x => x.id==fmaterialBean)
      if (item.length>0){
        return item[0]
      }else {
        return '-'
      }
    },

    formattedCurrencyValue(value){
      if (!value){return 0 }
      return parseFloat(Math.round(value)).toLocaleString()
    }
  },
  mounted() {
    this.fetchParent()
    // this.runExtendedFilter() //dipindah ke fetchParent
  }

}
</script>

<style scoped>

</style>